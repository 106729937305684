<template>
  <div>
    <h5>Flows</h5>
    <table
      v-if="flowAreas.length"
      :class="['table kendo-table table-kendo mb-2', { 'is-invalid': this.$validator.errors.has('FlowAreas') }]"
    >
      <colgroup>
        <col width="35">
      </colgroup>
      <thead>
        <tr>
          <th />
          <th>{{ $t('name') }}</th>
          <th>{{ $t('active') }}</th>
          <th>{{ $t('options') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in flowAreas"
          :key="`flowArea-tableRow-${ index }`"
        >
          <td>{{ index }}.</td>
          <td>{{ item.name }}</td>
          <td>
            <toggle-button
              v-model="item.active"
              :width="50"
              :height="24"
              :font-size="12"
              class="m-0"
            />
          </td>
          <td>
            <div class="btn-group btn-group-sm flex-wrap">
              <button
                class="btn btn-sm btn-primary"
                @click="changePtz(item.initArea.position)"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="directions"
                />{{ $t('moveTo') }}
              </button>
              <button
                class="btn btn-sm btn-primary"
                @click="showSidebar(item)"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="edit"
                />
                <span>{{ $t('edit') }}</span>
              </button>
              <button
                class="btn btn-sm btn-secondary"
                @click="removeItem(index)"
              >
                <font-awesome-icon
                  class="mr-2 gray"
                  icon="trash"
                />
                <span>{{ $t('remove') }}</span>
              </button>
              <button
                class="btn btn-sm btn-secondary"
                @click="moveUp(index)"
              >
                <font-awesome-icon
                  icon="arrow-up"
                />
              </button>
              <button
                class="btn btn-sm btn-secondary"
                @click="moveDown(index)"
              >
                <font-awesome-icon
                  icon="arrow-down"
                />
              </button>
              <input
                v-model="item.flowTimeStart"
                placeholder="08:00"
                type="text"
                style="width: 45px;"
              >
              <input
                v-model="item.flowTimeEnd"
                placeholder="16:00"
                type="text"
                style="width: 45px;"
              >
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <hr v-else>
    <span
      v-show="errors.has('FlowAreas')"
      class="badge badge-danger"
    >{{ errors.first('FlowAreas') }}</span>
    <button
      class="btn btn-sm btn-primary float-right"
      @click="addItem()"
    >
      <font-awesome-icon
        class="mr-2"
        icon="plus"
      />
      <span>{{ $t('add') }}</span>
    </button>
    <div class="clearfix" />

    <Sidebar
      :sidebar-width="800"
      :show-sidebar="displaySidebar"
      @close="hideSidebar()"
    >
      <FlowEditor
        v-if="displaySidebar"
        :flow-area="flowArea"
        :lane-config="laneConfig"
        @showMotionDetectionArea="showMotionDetectionArea"
        @changePtz="changePtz"
        @update="update"
        @close="hideSidebar()"
      />
    </Sidebar>
  </div>
</template>

<script>
// import components
import Sidebar from '@/components/Base/Sidebar.vue';

export default {
  name: 'FlowConfiguration',
  components: {
    FlowEditor: () => import('./FlowEditor/FlowEditor.vue'),
    Sidebar,
  },
  props: {
    flowAreas: {
      type: Array,
      required: true
    },
    laneConfig: {
      type: Object,
      required: true
    },
    sfConfig: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      displaySidebar: false,
      flowArea: null
    }
  },
  methods: {
    moveUp (flow) {
      if(flow != 0) {
        var prevItem = this.flowAreas[flow - 1];
        var currentItem = this.flowAreas[flow];
        var currId = currentItem.id;
        var prevId = prevItem.id;
        currentItem.id = prevId;
        prevItem.id = currId;
        this.flowAreas.splice((flow - 1), 1, currentItem);
        this.flowAreas.splice((flow), 1, prevItem);
        this.update();
      }
    },
    moveDown (flow) {
      var count = this.flowAreas.length;
      if(flow < (count - 1)) {
        var nextItem = this.flowAreas[flow + 1];
        var currentItem = this.flowAreas[flow];
        var currId = currentItem.id;
        var nextId = nextItem.id;
        currentItem.id = nextId;
        nextItem.id = currId;
        this.flowAreas.splice((flow + 1), 1, currentItem);
        this.flowAreas.splice((flow), 1, nextItem);
        this.update();
      }
    },
    showSidebar (flowArea) {
      //Show flow area editor
      this.flowArea = flowArea;
      this.displaySidebar = true;
      this.$emit('currentFlow', this.flowArea);
    },
    hideSidebar () {
      //Hide flow area editor
      this.flowArea = null;
      this.displaySidebar = false;
      this.showMotionDetectionArea(false);
      this.$emit('currentFlow', null);
    },
    showMotionDetectionArea (visible) {
      this.$emit('toogleMotionDetectionArea', visible);
    },
    addItem () {
      //Get next id from the highest +1
      let maxId = 0;
      this.flowAreas.map(function (obj) {
        if (obj.id > maxId) {
          maxId = obj.id
        }
      });
      maxId++;

      this.axios.get(`/Config/GetNewFlowArea`)
        .then((response) => {
          let flowArea = response.data;
          flowArea.name = `Flow ${ maxId }`
          flowArea.id = maxId;
          
          if(this.sfConfig && this.sfConfig.openingHours && this.sfConfig.openingHours.length > 0) {
            let opening = this.sfConfig.openingHours[0].openTime;
            let closing = this.sfConfig.openingHours[0].closeTime;
            flowArea.flowTimeStart = opening.substring(0, opening.lastIndexOf(':'));
            flowArea.flowTimeEnd = closing.substring(0, closing.lastIndexOf(':'));
          }

          this.flowAreas.push(flowArea);
          this.update();
        })
        .catch((error) => {
          this.$snotify.error(this.$t('videoDirectorConfigRecorderFormComponent.flowAreaNotAdded') + ' ' + error);
        })
    },
    removeItem (removeIndex) {
      this.flowAreas.splice(removeIndex, 1);
      this.update();
    },
    update () {
      this.$emit('update');
    },
    changePtz (position) {
      // if (this.displayMotionDetectionArea) {
      //   if (this.flowArea.initArea.position.x != position.x || this.flowArea.initArea.position.y != position.y) {
      //     return;
      //   }
      // }

      this.$emit('changePtz', position);
    }
  }
}
</script>